<div class="row listing-find-body border-bottom mt-1">
  <div class="col-md-1">
    <img class="listing-find-logo" [src]="getIconToDisplay()">
  </div>
  <div class="col-md-1">
    <p>{{ getDateOfBirth() | date }}</p>
  </div>
  <div class="col-md-2">
    <p class="listing-find-card-title">{{ item.breederInfo?.firstName }} {{ item.breederInfo?.lastName }}</p>
    <p class="listing-find-text block-with-text">{{ item.breederInfo?.email }}</p>
    <p class="listing-find-text block-with-text"><a style="color: blue;" href="{{ item.breederInfo?.breederWebsite }}">{{ item.breederInfo?.kennelName }}</a></p>
    <p class="listing-find-text block-with-text">{{ item.breederInfo?.city }}, {{item.breederInfo?.state}} {{ item.breederInfo?.zipCode}}</p>
  </div>
  <div class="col-md-3">
    <p class="listing-find-text">{{ item.sireName }}</p>
    <p class="listing-find-text block-with-text">Health: {{ item.sireHealth }}</p>
  </div>
  <div class="col-md-3">
    <p class="listing-find-text">{{ item.damName }}</p>
    <p class="listing-find-text block-with-text">Health: {{ item.damHealth }}</p>
  </div>
  <div class="col-md-2">
    <p class="listing-find-text">{{ item.description }}</p>
  </div>
</div>
