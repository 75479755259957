import {UserState} from '../models/user.model';
import * as userActions from '../actions/user.actions';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
export const initialState: UserState = {
  user: {identity: '', firstName: '', lastName: '', waId: 0, email: '', status: '', groups: [],
    memberSince: '', breederEmail: '', kennelName: '', facebook: '', website: ''},
  authenticated: false,
  token: ''
};
export const getUserState = createFeatureSelector<UserState>('userState');
export const getUser = createSelector(getUserState, (state: UserState) => state);

export const reducers = createReducer(initialState,
  on(UserActions.loginUserAction, (state, {inUser}) =>
    ({...state, user: inUser.user,
      authenticated: inUser.authenticated,
      token: inUser.token
    })),
  on(UserActions.setAuthenticatedUserAction, (state, {inAuth}) =>
    ({...state, authenticated: inAuth})),
  on(UserActions.setTokenUserAction, (state, {inToken}) =>
    ({...state, token: inToken})),
  on(UserActions.updateUserAction, (state, {inUser}) =>
    ({...state, user: inUser
    })),
  on(UserActions.loginUserAction, state  =>
    ({...state, state: initialState
    })),

);

/*
export function reducer(state = initialState, action: userActions.All): UserState {
  switch (action.type) {
    case userActions.LOGIN: {
      return Object.assign({}, state, {
        user: action.payload.user,
        authenticated: action.payload.authenticated,
        token: action.payload.token
      });
    }
    case userActions.SETAUTH: {
      return Object.assign({}, state, {
        authenticated: action.payload
      });
    }
    case userActions.SETTOKEN: {
      return Object.assign({}, state, {
        token: action.payload
      });
    }
    case userActions.UPDATEUSER: {
      return Object.assign({}, state, {
        user: action.payload
      });
    }
    case userActions.LOGOUT: {
      return Object.assign({}, state, {
        user: {identity: '', firstName: '', lastName: '', waId: 0, email: '', status: '', groups: [],
          memberSince: '', breederEmail: '', kennelName: '', facebook: '', website: ''},
        authenticated: false,
        token: ''
      });
    }
    default: {
      return state;
    }
  }
}
*/
