import { UserState } from '../models/user.model';
import * as userReducer from '../reducers/user';
import { createReducer, on } from '@ngrx/store';
import { initialState } from './user';
import * as UserActions from '../actions/user.actions';

export interface AppState {
  userState: UserState;
}

export const reducers = {
  userState: userReducer.reducers
};


