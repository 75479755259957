import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { RollbarService } from './services/rollbar.service';
import { UserState } from './models/user.model';
import { VersionCheckService } from './services/versioncheck.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as Rollbar from 'rollbar';
import * as userReducer from './reducers/user';
import { environment } from '../environments/environment';
import { initialState } from './reducers/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PuppyReferral';
  private unsubscribe$ = new Subject<void>();
  isLoggedIn: boolean;
  showSpinner: boolean;
  currentUser: UserState = initialState;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private router: Router,
              private ngZone: NgZone,
              public store: Store<UserState>,
              private versionCheck: VersionCheckService) {
    this.isLoggedIn = false;
    this.showSpinner = false;
  }

  ngOnInit() {
    this.store.select(userReducer.getUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserState) => {
        this.ngZone.run(() => {
          this.currentUser = user;
          this.isLoggedIn = user.authenticated;
        });
      });
    this.versionCheck.initVersionCheck(environment.versioncheckUrl, 1000 * 60 * 60); // check every hour
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
