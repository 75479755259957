import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PuppyFindComponent } from './puppy-find/puppy-find.component';
import { PuppyListingAdminComponent } from './puppy-listing-admin/puppy-listing-admin.component';
import { PuppyListingComponent } from './puppy-listing/puppy-listing.component';

const routes: Routes = [
  {
    path: '', component: PuppyListingComponent
  },
  {
    path: 'admin', component: PuppyListingAdminComponent
  },
  {
    path: 'search', component: PuppyFindComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
