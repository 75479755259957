import { Component, Input, OnInit } from '@angular/core';
import { Listing } from '../models/listing.model';

@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss']
})
export class ListingCardComponent implements OnInit {

  @Input() item: Listing;

  constructor() {
    this.item = {}
  }

  ngOnInit(): void {
  }

  getIconToDisplay() {
    return 'assets/images/' + this.item.listingType + '.jpg';
  }

}
