import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User, UserState } from '../models/user.model';
import { RollbarService } from '../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as userReducer from '../reducers/user';
import { initialState } from '../reducers/user';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ListingService } from '../services/listing.service';
import { HttpParams } from '@angular/common/http';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { Dog } from '../models/dog.model';
import { DogService } from '../services/dog.service';
import { DatePipe } from '@angular/common';
import { AuthModel } from '../models/auth.model';
import { DogSearchComponent } from '../dog-search/dog-search.component';
import { setAuthenticatedUserAction, setTokenUserAction, updateUserAction } from '../actions/user.actions';

@Component({
  selector: 'app-puppy-listing',
  templateUrl: './puppy-listing.component.html',
  styleUrls: ['./puppy-listing.component.scss']
})
export class PuppyListingComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  isLoggedIn: boolean;
  finishedAdding: boolean;
  showSpinner: boolean;
  currentUser: UserState = initialState;
  stateUser: UserState = initialState;
  memberForm: FormGroup;
  errorModalRef: NgbModalRef | undefined;
  listingForm: FormGroup;
  listingType: string;
  submitted = false;
  thisYear: number;
  nextYear: number;
  workingDog: string = "";
  private infoModalRef: NgbModalRef | undefined;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private router: Router,
              private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private auth: AuthService,
              private api: ApiService,
              private listingService: ListingService,
              private dogService: DogService,
              private datePipe: DatePipe,
              private ngZone: NgZone,
              public store: Store<UserState>) {
    this.isLoggedIn = false;
    this.finishedAdding = false;
    this.showSpinner = false;
    this.listingType = 'puppies';
    const today = new Date();
    this.thisYear = today.getUTCFullYear();
    this.nextYear = this.thisYear + 1;
    this.listingForm = this.fb.group({
      listingType: ['puppies'],
      dateOfBirth: [''],
      malesCount: [''],
      femalesCount: [''],
      description: [''],
      sireName: [''],
      sireTitles: [''],
      sireHealth: [''],
      sireId: [0],
      sireCertNum: [''],
      sireCertImagePath: [''],
      damName: [''],
      damTitles: [''],
      damHealth: [''],
      damId: [0],
      damCertNum: [''],
      damCertImagePath: [''],
      wasEdited: [0],
      expectedMonth: [1],
      expectedYear: [this.thisYear],
      adultName: [''],
      adultCertNum: [''],
      adultHealth: [''],
      adultCertImagePath: [''],
      adultGender: ['male'],
      adultDogId: [0],
      breederId: [0, Validators.required],
      agreements: [false, Validators.requiredTrue]
    });
    this.memberForm = this.fb.group({
      emailMember: ['', Validators.required],
      pwdMember: ['', Validators.required]
    });
    this.setupValidators();
  }

  ngOnInit() {
    this.currentUser = {user: {
        waId: 0, lastName: '', firstName: '', identity: '', email: '', status: '', groups: [],
        memberSince: '', breederEmail: '', kennelName: '', facebook: '', website: ''
      }, token: '', authenticated: false};
    this.showSpinner = false;
    this.store.select(userReducer.getUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserState) => {
        this.ngZone.run(() => {
          if (user) {
            this.currentUser = user;
            this.isLoggedIn = user.authenticated;
            if (!user.token) {
              setTimeout(() => {
                this.showSpinner = true;
                this.auth.validateToken()
                  .subscribe(
                    (res) => {
                      this.showSpinner = false;
                      if (res.valid) {
                        const newUser: User = {
                          identity: '',
                          firstName: res.firstName,
                          lastName: res.lastName,
                          email: res.email,
                          waId: res.waId,
                          memberSince: res.memberSince,
                          breederEmail: res.breederEmail,
                          kennelName: res.kennelName,
                          facebook: res.facebook,
                          website: res.website,
                          status: res.status
                        };
                        if (res.memberSince) {
                          const sinceDate = Date.parse(res.memberSince);
                          let canUseThisService = true;
                          if (Date.now() - sinceDate < 1000 * 60 * 60 * 24 * 365) {
                            canUseThisService = false;
                            console.log('member is too new');
                            setTimeout(() => {
                              this.isLoggedIn = false;
                              this.rollbar.info('Member too new for user: ' + res.email);
                              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                              this.errorModalRef.componentInstance.errorMessage = '';
                              this.errorModalRef.componentInstance.longErrorMessage = 'You must have been a member of the NCA for longer than 1 year in order to use this service.';
                            }, 500);
                          } else if (res.status !== 'Active') {
                            canUseThisService = false;
                            console.log('member is not active');
                            setTimeout(() => {
                              this.isLoggedIn = false;
                              this.rollbar.info('Member is not active for user: ' + res.email);
                              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                              this.errorModalRef.componentInstance.errorMessage = '';
                              this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
                            }, 500);
                          }
                          if (canUseThisService) {
                            if (navigator.cookieEnabled) {
                              const oldToken = localStorage.getItem('id_token');
                              if (oldToken) {
                                this.store.dispatch(setTokenUserAction({inToken: oldToken}));
                              }
                            }
                            this.isLoggedIn = true;
                            this.store.dispatch(setAuthenticatedUserAction({inAuth: true}));
                            this.store.dispatch(updateUserAction({inUser: newUser}));
                            this.setupValidators();
                          }
                        } else {
                          this.isLoggedIn = false;
                        }
                      }
                    },
                    (err) => {
                      this.isLoggedIn = false;
                      this.showSpinner = false;
                    }
                  );
              }, 500);
            }

          }
        });
      });
  }

  loginMember() {
    this.showSpinner = true;
    this.auth.login(this.memberForm.controls['emailMember'].value, this.memberForm.controls['pwdMember'].value)
      .subscribe(
        (res) => this.saveLoginData(res),
        (err) => this.manageLoginError(err)
      );
  }

  manageLoginError(err: AuthModel) {
    this.ngZone.run(() => {
      this.showSpinner = false;
      setTimeout(() => {
        this.rollbar.info('Login failure: ' + err);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  saveLoginData(res: AuthModel) {
    this.showSpinner = false;
    this.activeModal.close();
    if (res.valid) {

      this.auth.setSession(res);
      const newUser: User = {
        identity: '',
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        waId: res.waId,
        memberSince: res.memberSince,
        breederEmail: res.breederEmail,
        kennelName: res.kennelName,
        facebook: res.facebook,
        website: res.website,
        status: res.status,
        groups: res.groups
      };
      if (res.memberSince) {
        const sinceDate = Date.parse(res.memberSince);
        let canUseThisService = true;
        if (Date.now() - sinceDate < 1000 * 60 * 60 * 24 * 365) {
          canUseThisService = false;
          console.log('member is too new');
          setTimeout(() => {
            this.isLoggedIn = false;
            this.rollbar.info('Member too new for user: ' + res.email);
            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
            this.errorModalRef.componentInstance.errorMessage = '';
            this.errorModalRef.componentInstance.longErrorMessage = 'You must have been a member of the NCA for longer than 1 year in order to use this service.';
          }, 500);
        } else if (res.status !== 'Active') {
          canUseThisService = false;
          console.log('member is not active');
          setTimeout(() => {
            this.isLoggedIn = false;
            this.rollbar.info('Member is not active for user: ' + res.email);
            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
            this.errorModalRef.componentInstance.errorMessage = '';
            this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
          }, 500);
        }
        if (canUseThisService) {
          if (res.token) {
            this.store.dispatch(setTokenUserAction({inToken: res.token}));
          }
          this.store.dispatch(setAuthenticatedUserAction({inAuth: true}));
          this.store.dispatch(updateUserAction({inUser: newUser}));
          this.setupValidators();
        }
      } else {
        this.isLoggedIn = false;
      }
    } else {
      console.log('login failed');
      setTimeout(() => {
        this.isLoggedIn = false;
        this.rollbar.info('Login failure for user: ' + res.email);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    }
  }

  setupValidators() {
    setTimeout(() => {
      switch (this.listingType) {
        case 'puppies':
          this.listingForm.controls['malesCount'].setValidators(Validators.required);
          this.listingForm.controls['femalesCount'].setValidators(Validators.required);
          this.listingForm.controls['adultName'].clearValidators();
          this.listingForm.controls['adultCertNum'].clearValidators();
          this.listingForm.controls['dateOfBirth'].setValidators(Validators.required);
          this.listingForm.controls['sireName'].setValidators([Validators.maxLength(1000), Validators.required]);
          this.listingForm.controls['damName'].setValidators([Validators.maxLength(1000), Validators.required]);
          this.listingForm.controls['description'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireTitles'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['damTitles'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireHealth'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['damHealth'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireCertNum'].setValidators(Validators.required);
          this.listingForm.controls['damCertNum'].setValidators(Validators.required);
          break;
        case 'planned':
          this.listingForm.controls['malesCount'].clearValidators();
          this.listingForm.controls['femalesCount'].clearValidators();
          this.listingForm.controls['adultName'].clearValidators();
          this.listingForm.controls['adultCertNum'].clearValidators();
          this.listingForm.controls['dateOfBirth'].clearValidators();
          this.listingForm.controls['sireName'].setValidators([Validators.maxLength(1000), Validators.required]);
          this.listingForm.controls['damName'].setValidators([Validators.maxLength(1000), Validators.required]);
          this.listingForm.controls['description'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireTitles'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['damTitles'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireHealth'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['damHealth'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireCertNum'].setValidators(Validators.required);
          this.listingForm.controls['damCertNum'].setValidators(Validators.required);
          break;
        case 'adult':
          this.listingForm.controls['malesCount'].clearValidators();
          this.listingForm.controls['femalesCount'].clearValidators();
          this.listingForm.controls['adultName'].setValidators([Validators.required, Validators.maxLength(1000) ]);
          this.listingForm.controls['adultCertNum'].setValidators([Validators.required, Validators.maxLength(32) ]);
          this.listingForm.controls['dateOfBirth'].setValidators(Validators.required);
          this.listingForm.controls['description'].setValidators(Validators.maxLength(1024));
          this.listingForm.controls['sireName'].clearValidators();
          this.listingForm.controls['damName'].clearValidators();
          this.listingForm.controls['sireTitles'].clearValidators();
          this.listingForm.controls['damTitles'].clearValidators();
          this.listingForm.controls['sireHealth'].clearValidators();
          this.listingForm.controls['damHealth'].clearValidators();
          this.listingForm.controls['sireCertNum'].clearValidators();
          this.listingForm.controls['damCertNum'].clearValidators();
          break;
      }
      this.listingForm.controls['malesCount'].updateValueAndValidity();
      this.listingForm.controls['femalesCount'].updateValueAndValidity();
      this.listingForm.controls['adultName'].updateValueAndValidity();
      this.listingForm.controls['adultCertNum'].updateValueAndValidity();
      this.listingForm.controls['dateOfBirth'].updateValueAndValidity();
      this.listingForm.controls['sireName'].updateValueAndValidity();
      this.listingForm.controls['damName'].updateValueAndValidity();
      this.listingForm.controls['description'].updateValueAndValidity();
      this.listingForm.controls['sireTitles'].updateValueAndValidity();
      this.listingForm.controls['damTitles'].updateValueAndValidity();
      this.listingForm.controls['sireHealth'].updateValueAndValidity();
      this.listingForm.controls['damHealth'].updateValueAndValidity();
      this.listingForm.controls['sireCertNum'].updateValueAndValidity();
      this.listingForm.controls['damCertNum'].updateValueAndValidity();
      this.listingForm.controls['breederId'].setValue(this.currentUser.user.waId);
    });
  }

  listingTypeChanged() {
    this.listingType = this.listingForm.controls['listingType'].value;
    this.setupValidators();
  }
  addListing() {
    this.submitted = true;
    if ( this.listingForm.valid) {
      let formData = new HttpParams();
      Object.keys(this.listingForm.controls).forEach(key => {
        const abstractControl = this.listingForm.get(key);
        if (abstractControl?.value) {
          if (key === 'dateOfBirth') {
            const transDate = this.datePipe.transform(abstractControl.value, 'yyyy-MM-dd');
            formData = formData.append(key, transDate ?? '');
          } else {
            formData = formData.append(key, abstractControl.value);
          }
        }
      });
      this.showSpinner = true;
      this.listingService.addNewListing(formData)
        .subscribe(res => {
            this.ngZone.run((resp) => {
              setTimeout(() => {
                this.showSpinner = false;
                this.finishedAdding = true;
                this.infoModalRef = this.modalService.open(InfoModalComponent);
                this.infoModalRef.componentInstance.infoTitle = 'Listing Saved.';
                this.infoModalRef.componentInstance.infoMessage = 'Your listing was added successfully.';
              }, 500);
            });
          },
          error => {
            setTimeout(() => {
              this.showSpinner = false;
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'add a new listing. Error Message was: ' + error;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
    }
  }

  addAnother() {
    this.finishedAdding = false;
    this.listingForm.reset({
      listingType: 'puppies',
      dateOfBirth: '',
      malesCount: '',
      femalesCount: '',
      description: '',
      sireName: '',
      sireTitles: '',
      sireHealth: '',
      sireId: 0,
      sireCertNum: '',
      sireCertImagePath: '',
      damName: '',
      damTitles: '',
      damHealth: '',
      damId: 0,
      damCertNum: '',
      damCertImagePath: '',
      wasEdited: 0,
      expectedMonth: 0,
      expectedYear: this.thisYear,
      adultName: '',
      adultCertNum: '',
      adultHealth: '',
      adultCertImagePath: '',
      adultGender: 'male',
      adultDogId: 0,
      breederId: 0,
      agreements: false
    });
    this.submitted = false;
    this.listingType = 'puppies';
    this.setupValidators();
  }

  lookupAdult() {
    this.workingDog = 'adult';
    this.onSearchDogDatabase();
  }

  lookupSire() {
    this.workingDog = 'sire';
    this.onSearchDogDatabase();
  }

  lookupDam() {
    this.workingDog = 'dam';
    this.onSearchDogDatabase();
  }

  onSearchDogDatabase() {
    const options: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: true,
      scrollable: true,
      size: 'lg',
    };
    const modalRef = this.modalService.open(DogSearchComponent, options);
    const component = modalRef.componentInstance as DogSearchComponent;
    component.isLoggedIn = this.isLoggedIn;
    modalRef.result
      .then((dog?: Dog) => {
        this.showSpinner = true;
        if (dog) {
          this.dogService.getDogTitles(dog.dog_id)
            .subscribe((res) => {
              this.showSpinner = false;
              switch (this.workingDog) {
                case 'sire':
                  this.listingForm.controls['sireName'].setValue(res.titleString);
                  this.listingForm.controls['sireName'].updateValueAndValidity();
                  break;
                case 'dam':
                  this.listingForm.controls['damName'].setValue(res.titleString);
                  this.listingForm.controls['damName'].updateValueAndValidity();
                  break;
                case 'adult':
                default:
                  this.listingForm.controls['adultName'].setValue(res.titleString);
                  this.listingForm.controls['adultName'].updateValueAndValidity();
                  break;
              }

            });
          this.dogService.getDogHealth(dog.dog_id)
            .subscribe((res) => {
              this.showSpinner = false;
              switch (this.workingDog) {
                case 'sire':
                  this.listingForm.controls['sireHealth'].setValue(res.health);
                  this.listingForm.controls['sireHealth'].updateValueAndValidity();
                  break;
                case 'dam':
                  this.listingForm.controls['damHealth'].setValue(res.health);
                  this.listingForm.controls['damHealth'].updateValueAndValidity();
                  break;
                case 'adult':
                default:
                  this.listingForm.controls['adultHealth'].setValue(res.health);
                  this.listingForm.controls['adultHealth'].updateValueAndValidity();
                  break;
              }

            });

          switch (this.workingDog) {
            case 'sire':
              this.listingForm.controls['sireId'].setValue(dog.dog_id);
              this.listingForm.controls['sireCertNum'].setValue(dog.registry_id);
              this.listingForm.controls['sireTitles'].setValue(dog.titles);
              this.listingForm.controls['sireHealth'].setValue(dog.health);
              this.listingForm.controls['sireId'].updateValueAndValidity();
              this.listingForm.controls['sireName'].updateValueAndValidity();
              this.listingForm.controls['sireCertNum'].updateValueAndValidity();
              this.listingForm.controls['sireTitles'].updateValueAndValidity();
              this.listingForm.controls['sireHealth'].updateValueAndValidity();
              break;
            case 'dam':
              this.listingForm.controls['damId'].setValue(dog.dog_id);
              this.listingForm.controls['damCertNum'].setValue(dog.registry_id);
              this.listingForm.controls['damId'].updateValueAndValidity();
              this.listingForm.controls['damName'].updateValueAndValidity();
              this.listingForm.controls['damCertNum'].updateValueAndValidity();
              break;
            case 'adult':
            default:
              this.listingForm.controls['adultDogId'].setValue(dog.dog_id);
              this.listingForm.controls['dateOfBirth'].setValue(dog.date_of_birth);
              this.listingForm.controls['adultCertNum'].setValue(dog.registry_id);
              this.listingForm.controls['adultGender'].setValue(dog.sex === 'F' ? 'female' : 'male');
              this.listingForm.controls['adultDogId'].updateValueAndValidity();
              this.listingForm.controls['adultName'].updateValueAndValidity();
              this.listingForm.controls['dateOfBirth'].updateValueAndValidity();
              this.listingForm.controls['adultCertNum'].updateValueAndValidity();
              this.listingForm.controls['adultGender'].updateValueAndValidity();
              break;
          }
        }
      });

  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
