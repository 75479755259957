<div class="header">
  <div class="container-fluid">
    <div *ngIf="showHeader" class="logo">
      <img src="assets/images/NCAtrans900909.png">
    </div>
    <div class="header-right">

      <span class="menu"></span>
      <div class="top-menu">
        <ul>
          <li><a class="active" href="">Home</a></li>
          <li><a href="https://members.ncanewfs.org/calendar/">Events</a></li>
          <li><a href="https://www.newfdoghealth.org">Health Center</a></li>
          <li><a href="http://www.newfbooks.org">Newfoundland Dog Library</a></li>
          <li><a href="http://www.ncanewfs.org">NCA Home</a></li>
        </ul>
      </div>
      <!-- script for menu -->
      <script>
        $( "span.menu" ).click(function() {
          $( ".top-menu" ).slideToggle( "slow", function() {
            // Animation complete.
          });
        });
      </script>
      <!-- script for menu -->

    </div>
    <div class="clearfix"></div>
  </div>
</div>
