import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AllListings } from '../models/listing.model';

@Injectable()
export class ListingService {

  constructor(private apiService: ApiService,
              private ngZone: NgZone) {
  }

  addNewListing(listingData: HttpParams): Observable<any> {
    return this.apiService.postForm('/listings', listingData);
  }

  getListings(zipcode: string): Observable<AllListings> {
    if (zipcode.length > 0) {
      return this.apiService.get('/listings?fromZipCode=' + zipcode);
    } else {
      return this.apiService.get('/listings');
    }
  }

  changeStatus(newStatus: string, activated: boolean, listingId: number): Observable<any> {
    let formData = new HttpParams();
    formData = formData.append('status', newStatus);
    formData = formData.append('activated', (activated ? 'true' : 'false'));
    return this.apiService.putForm('/listings/' + listingId + '/status', formData);
  }
}
