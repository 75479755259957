<div class="modal-header">
  <h2>An error occurred</h2>
  <button style="margin-bottom: 10px;" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">X</span>
  </button>
</div>
<div *ngIf="errorMessage.length > 0" class="modal-body my-3">
  <p class="px-4">Something went wrong while attempting to {{ errorMessage }}.
    Please try again.  If this problem continues, please send an email to <a href="mailto:dan@dogfishsoftware.com">Dan Zimmerman</a></p>
</div>
<div *ngIf="longErrorMessage.length > 0" class="modal-body my-3">
  <p class="px-4">{{ longErrorMessage }}</p>
</div>
