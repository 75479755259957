import {
  Component,
  NgZone,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
  Input
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dog, FoundDogs } from '../models/dog.model';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DogService } from '../services/dog.service';

const unknown = 'Unknown';
let searchValue = '';
let foundDogs: FoundDogs = { dogs: [], limit: 100, total: 0, offset: 0 };

@Component({
  selector: 'app-dog-search',
  templateUrl: './dog-search.component.html',
  styleUrls: ['./dog-search.component.scss']
})
export class DogSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchInput') searchElement?: ElementRef;
  @Input() isLoggedIn: boolean = false;

  showDogTable = false;
  showDogTableSpinner = false;
  noResultsFoundFromSearch = false;
  searchResultsString = 'No Results Found';
  get foundDogs() {
    return foundDogs;
  }

  private unsubscribe$ = new Subject<void>();

  maxAgeControl = new FormControl(true);
  searchControl = new FormControl(searchValue);

  constructor(
    private activeModal: NgbActiveModal,
    private dogService: DogService,
    private changeDetect: ChangeDetectorRef,
    private ngZone: NgZone,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if (foundDogs.dogs?.length) {
      this.showDogTable = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchElement?.nativeElement.focus();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEnterManually() {
    this.activeModal.close();
  }

  search() {
    if (this.showDogTableSpinner) {
      return;
    }
    searchValue = this.searchControl.value;
    if (searchValue.length < 3) {
      alert('Please enter at least three characters.');
      this.searchElement?.nativeElement.focus();
      return;
    }
    foundDogs.dogs = [];
    this.noResultsFoundFromSearch = false;
    this.showDogTable = true;
    this.showDogTableSpinner = true;
    this.dogService.findDogs(searchValue, this.maxAgeControl.value ? 13 * 12 : 0, this.isLoggedIn) // maxAge is 13 years although that may also be quite a stretch
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
          this.ngZone.run(() => {
            this.showDogTableSpinner = false;
            this.noResultsFoundFromSearch = false;
            foundDogs = res;
            switch (foundDogs.dogs.length) {
              case 0:
                this.noResultsFoundFromSearch = true;
                this.searchResultsString = 'No Results Found';
                break;
              case 1:
                this.selectDog(foundDogs.dogs[0]);
                return;
              default:
                this.changeDetect.markForCheck();
                if (foundDogs.total > foundDogs.limit) {
                  setTimeout(() => {
                    alert(
                      `${foundDogs.total} results were found. The first ${foundDogs.limit} results are shown.
                      Enter more characters/numbers of the dog's Id or name to find fewer search results.`);
                  });
                }
            }
          });
        },
        error2 => {
          this.ngZone.run(() => {
            console.log(error2);
            this.searchResultsString = 'An error occurred. Please try again.';
            this.noResultsFoundFromSearch = true;
            this.showDogTableSpinner = false;
            this.changeDetect.markForCheck();
          });
        });
  }

  getRegisteredName(dog: Dog) {
    return dog.registered_name || unknown;
  }

  getRegistryId(dog: Dog) {
    return dog.registry_id || unknown;
  }

  getDateOfBirth(dog: Dog) {
    return dog.date_of_birth || unknown;
  }

  selectDog(dog: Dog) {
    this.activeModal.close(dog);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  isDirty() {
    return true;
  }

}
