import { Action, createAction, props } from '@ngrx/store';
import {User, UserState} from '../models/user.model';

export const LOGIN = 'Login';
export const LOGOUT = 'Logout';
export const SETAUTH = 'SetAuth';
export const SETTOKEN = 'SetToken';
export const UPDATEUSER = 'UpdateUser';

export const loginUserAction = createAction(LOGIN, props<{inUser: UserState}>())
/*
export class LoginUserAction implements Action {
  readonly type = LOGIN;
  constructor(public payload: UserState) {}
}
*/

export const setAuthenticatedUserAction = createAction(SETAUTH, props<{inAuth: boolean}>())
/*
export class SetAuthenticatedAction implements Action {
  readonly type = SETAUTH;
  constructor(public payload: boolean) {}
}
*/

export const setTokenUserAction = createAction(SETTOKEN, props<{inToken: string}>())
/*
export class SetTokenAction implements Action {
  readonly type = SETTOKEN;
  constructor(public payload: string) {}
}
*/

export const updateUserAction = createAction(UPDATEUSER, props<{inUser: User}>())
/*
export class UpdateUserAction implements Action {
  readonly type = UPDATEUSER;
  constructor(public payload: User) {}
}
*/

export const logoutUserAction = createAction(LOGOUT)
/*
export class LogoutUserAction implements Action {
  readonly type = LOGOUT;
}
*/

// export type All = LoginUserAction | LogoutUserAction | SetAuthenticatedAction | SetTokenAction | UpdateUserAction;
