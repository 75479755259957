import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User, UserState } from '../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { ListingService } from '../services/listing.service';
import { DogService } from '../services/dog.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import * as userReducer from '../reducers/user';
import { takeUntil } from 'rxjs/operators';
import * as userActions from '../actions/user.actions';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Listing } from '../models/listing.model';
import { initialState } from '../reducers/user';
import { AuthModel } from '../models/auth.model';

@Component({
  selector: 'app-puppy-listing-admin',
  templateUrl: './puppy-listing-admin.component.html',
  styleUrls: ['./puppy-listing-admin.component.scss']
})
export class PuppyListingAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoggedIn: boolean;
  showSpinner: boolean;
  currentUser: UserState = initialState;
  stateUser: UserState = initialState;
  memberForm: FormGroup;
  errorModalRef: NgbModalRef | undefined;
  pending: Listing[];
  approved: Listing[];
  expired: Listing[];
  denied: Listing[];

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private router: Router,
              private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private auth: AuthService,
              private api: ApiService,
              private listingService: ListingService,
              private dogService: DogService,
              private datePipe: DatePipe,
              private ngZone: NgZone,
              public store: Store<UserState>) {
    this.isLoggedIn = false;
    this.showSpinner = false;
    this.pending = [];
    this.approved = [];
    this.denied = [];
    this.expired = [];
    this.memberForm = this.fb.group({
      emailMember: ['', Validators.required],
      pwdMember: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.currentUser = {user: {
        waId: 0, lastName: '', firstName: '', identity: '', email: '', status: '', groups: [],
        memberSince: '', breederEmail: '', kennelName: '', facebook: '', website: ''
      }, token: '', authenticated: false};
    this.showSpinner = false;
    this.store.select(userReducer.getUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserState) => {
        this.ngZone.run(() => {
          this.currentUser = user;
          this.isLoggedIn = user.authenticated;
          if (!user.token) {
            setTimeout(() => {
              this.showSpinner = true;
              this.auth.validateToken()
                .subscribe(
                  (res) => {
                    this.showSpinner = false;
                    if (res.valid) {
                      const newUser: User = {
                        identity: '',
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        waId: res.waId,
                        memberSince: res.memberSince,
                        breederEmail: res.breederEmail,
                        kennelName: res.kennelName,
                        facebook: res.facebook,
                        website: res.website,
                        status: res.status,
                        groups: res.groups
                      };
                      if (res.memberSince) {
                        const sinceDate = Date.parse(res.memberSince);
                        let canUseThisService = true;
                        const memberOfGroup = res.groups?.filter(g => g.Id === 349510); // checking for trac for now to test TODO: change this to be lynn Nuss only
                        // test if DZ is logging in
                        if (res.waId === 28792390) {
                          console.log('DZ logging in');
                        } else if (Date.now() - sinceDate < 1000 * 60 * 60 * 24 * 365) {
                          canUseThisService = false;
                          console.log('member is too new');
                          setTimeout(() => {
                            this.isLoggedIn = false;
                            this.rollbar.info('Member too new for user: ' + res.email);
                            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                            this.errorModalRef.componentInstance.errorMessage = '';
                            this.errorModalRef.componentInstance.longErrorMessage = 'You must have been a member of the NCA for longer than 1 year in order to use this service.';
                          }, 500);
                        } else if (res.status !== 'Active') {
                          canUseThisService = false;
                          console.log('member is not active');
                          setTimeout(() => {
                            this.isLoggedIn = false;
                            this.rollbar.info('Member is not active for user: ' + res.email);
                            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                            this.errorModalRef.componentInstance.errorMessage = '';
                            this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
                          }, 500);
                        } else if (memberOfGroup?.length === 0) {
                          canUseThisService = false;
                          console.log('member is not part of the referral group');
                          setTimeout(() => {
                            this.isLoggedIn = false;
                            this.rollbar.info('Member is not a member of the referral group, user: ' + res.email);
                            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                            this.errorModalRef.componentInstance.errorMessage = '';
                            this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the Breeder Referral Group of the NCA in order to use this service.';
                          }, 500);
                        }
                        if (canUseThisService) {
                          if (navigator.cookieEnabled) {
                            const oldToken = localStorage.getItem('id_token');
                            if (oldToken) {
                              this.store.dispatch(userActions.setTokenUserAction({inToken: oldToken}));
                            }
                          }
                          this.isLoggedIn = true;
                          this.store.dispatch(userActions.setAuthenticatedUserAction({inAuth: true}));
                          this.store.dispatch(userActions.updateUserAction({inUser: newUser}));
                          this.loadListingData();
                        }
                      } else {
                        this.isLoggedIn = false;
                      }

                    } else {
                      this.isLoggedIn = false;
                    }
                  },
                  (err) => {
                    this.isLoggedIn = false;
                    this.showSpinner = false;
                  }
                );
            }, 500);
          }

        });
      });
  }

  updateStatus(listindId: number, activated: boolean, newStatus: string) {
    if (newStatus !== '') {
      this.showSpinner = true;
      this.listingService.changeStatus(newStatus, activated, listindId)
        .subscribe(data => {
          this.showSpinner = false;
          this.loadListingData();
        })
    }
  }
  loadListingData() {
    if (this.currentUser.authenticated) {
      this.showSpinner = true;
      this.listingService.getListings('')
        .subscribe(data => {
          this.showSpinner = false;
          this.pending = data.pending;
          this.approved = data.approved;
          this.denied = data.denied;
          this.expired = data.expired;
        });
    }
  }
  loginMember() {
    this.showSpinner = true;
    this.auth.login(this.memberForm.controls['emailMember'].value, this.memberForm.controls['pwdMember'].value)
      .subscribe(
        (res) => this.saveLoginData(res),
        (err) => this.manageLoginError(err)
      );
  }

  manageLoginError(err: AuthModel) {
    this.ngZone.run(() => {
      this.showSpinner = false;
      setTimeout(() => {
        this.rollbar.info('Login failure: ' + err);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  saveLoginData(res: AuthModel) {
    this.showSpinner = false;
    this.activeModal.close();
    if (res.valid) {

      this.auth.setSession(res);
      const newUser: User = {
        identity: '',
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        waId: res.waId,
        memberSince: res.memberSince,
        breederEmail: res.breederEmail,
        kennelName: res.kennelName,
        facebook: res.facebook,
        website: res.website,
        status: res.status,
        groups: res.groups
      };
      const sinceDate = Date.parse(<string>res.memberSince);
      let canUseThisService = true;
      // 664567 -> Puppy Referral Admins Group
      const memberOfGroup = res.groups?.filter(g => g.Id === 664567);
      if (Date.now() - sinceDate < 1000 * 60 * 60 * 24 * 365) {
        canUseThisService = false;
        console.log('member is too new');
        setTimeout(() => {
          this.isLoggedIn = false;
          this.rollbar.info('Member too new for user: ' + res.email);
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'You must have been a member of the NCA for longer than 1 year in order to use this service.';
        }, 500);
      } else if (res.status !== 'Active') {
        canUseThisService = false;
        console.log('member is not active');
        setTimeout(() => {
          this.isLoggedIn = false;
          this.rollbar.info('Member is not active for user: ' + res.email);
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
        }, 500);
      } else if (memberOfGroup?.length === 0) {
        canUseThisService = false;
        console.log('member is not part of the referral group');
        setTimeout(() => {
          this.isLoggedIn = false;
          this.rollbar.info('Member is not a member of the referral group, user: ' + res.email);
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the Puppy Referral Admins Group of the NCA in order to use this service.';
        }, 500);
      }
      if (canUseThisService) {
        if (res.token) {
          this.store.dispatch(userActions.setTokenUserAction({inToken: res.token}));
        }
        this.store.dispatch(userActions.setAuthenticatedUserAction({inAuth: true}));
        this.store.dispatch(userActions.updateUserAction({inUser: newUser}));
        this.loadListingData();
      }
    } else {
      console.log('login failed');
      setTimeout(() => {
        this.isLoggedIn = false;
        this.rollbar.info('Login failure for user: ' + res.email);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    }
  }

  drop(event: CdkDragDrop<Listing[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const listingId = event.previousContainer.data[event.previousIndex].id ?? 0;
      let newStatus = '';
      // special case if a listing is "reactivated"
      let activated = (event.container.id === 'cdk-drop-list-1');
      switch (event.container.id) {
        case 'cdk-drop-list-0':
          newStatus = 'pending';
          break;
        case 'cdk-drop-list-1':
          newStatus = 'approved';
          break;
        case 'cdk-drop-list-2':
          newStatus = 'closed';
          break;
        case 'cdk-drop-list-3':
          newStatus = 'denied';
          break;
      }
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.updateStatus(listingId, activated, newStatus);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
