import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RollbarService } from './rollbar.service';
import * as Rollbar from 'rollbar';

@Injectable()
export class ApiService {
  public headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  public formHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  // Todo: Need to make allowance for test mode here and change the URL
  // PRODUCTION
  // public API_URL = 'https://wdevent-services.ncanewfs.org';
  // TEST
  public API_URL = environment.server;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private _http: HttpClient,
  ) {}

  public get(path: string): Observable<any> {
    return this._http.get(
      `${this.API_URL}${path}`,
      {headers: this.headers}
    );
  }

  public post(path: string, body: any): Observable<any> {
    return this._http.post(
      `${this.API_URL}${path}`,
      JSON.stringify(body),
      {headers: this.headers}
    );
  }
  public postForm(path: string, payload: any): Observable<any> {
    return this._http.post(
      `${this.API_URL}${path}`,
      payload,
      {headers: this.formHeaders}
    );
  }

  public put(path: string, body: any): Observable<any> {
    return this._http.put(
      `${this.API_URL}${path}`,
      JSON.stringify(body),
      {headers: this.headers}
    );
  }

  public putForm(path: string, payload: any): Observable<any> {
    return this._http.put(
      `${this.API_URL}${path}`,
      payload,
      {headers: this.formHeaders}
    );
  }

  public delete(path: string): Observable<any> {
    return this._http.delete(`${this.API_URL}${path}`, {headers: this.headers});
  }

  public setHeaders(headers: { [x: string]: string | string[]; }) {
    // console.log('headers' + this.headers + 'headers');
    Object.keys(headers)
      .forEach((header) => this.headers.set(header, headers[header]));
  }

}
