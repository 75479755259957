import { Component, Input, OnInit } from '@angular/core';
import { Listing } from '../models/listing.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listing-find-card',
  templateUrl: './listing-find-card.component.html',
  styleUrls: ['./listing-find-card.component.scss']
})
export class ListingFindCardComponent implements OnInit {

  @Input() item: Listing;

  constructor(private date: DatePipe) {
    this.item = {}
  }

  ngOnInit(): void {
  }

  getIconToDisplay() {
    return 'assets/images/' + this.item.listingType + '.jpg';
  }

  getMonth(month: number | undefined): string {
    switch (month) {
      case 0: return 'January';
      case 1: return 'February';
      case 2: return 'March';
      case 3: return 'April';
      case 4: return 'May';
      case 5: return 'June';
      case 6: return 'July';
      case 7: return 'August';
      case 8: return 'September';
      case 9: return 'October';
      case 10: return 'November';
      case 11: return 'December';
    }
    return 'January';
  }
  getDateOfBirth(): string | Date {
    if (this.item.dateOfBirth !== null) {
      return new Date(Date.parse(<string>this.item.dateOfBirth));
    } else {
      return 'Expected: ' + this.getMonth(this.item.expectedMonth) + ', ' + this.item.expectedYear;
    }
  }
}
