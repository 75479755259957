<div class="header">
  <span>Find Dog</span>
  <button style="margin-bottom: 10px;" type="button" class="close-icon" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">X</span>
  </button>

</div>

<div style="overflow: auto;">

  <div class="row">
    <ul>
      <li>Enter at least three characters of the dog's name or registration Id, then click or tap the Search button.</li>
      <li>Do not enter any titles (e.g., CH or WD).</li>
      <li>Do not enter the registry organization (e.g., AKC or CKC).</li>
    </ul>
  </div>

  <div class="row mb-2">
    <div class="col-md-8" style="margin: 0 2px">
      <input style="margin-left: 5px;" type="checkbox" class="form-check-input" id="maxAgeControl" [formControl]="maxAgeControl">
      Restrict search to dogs under 13 years old.  If you uncheck this checkbox, you can search through all known dogs regardless of age
    </div>
  </div>
  <div class="row justify-content-start">
    <div class="col-md-6" style="margin: 0 2px">
        <input class="w-100" placeholder="Dog Id or Name" #searchInput [formControl]="searchControl" (keyup.Enter)="search()">
    </div>
    <div class="col-md-4" style="margin: 0 2px">
      <button class="btn btn-sm btn-info btn-lookup btn-lookup-fixed mx-auto" type="button" style="width: 70px;" (click)="search()">
        Search
      </button>
    </div>
  </div>

  <div class="table-responsive" *ngIf="showDogTable">
    <p class="text-center" *ngIf="foundDogs.dogs.length > 0" style="font-size: larger; font-weight: bolder">
      Click or tap to select one of these {{ foundDogs.dogs.length }} search results:
    </p>
    <table class="table table-entry table-dog-entry selectable table-bordered table-hover mx-auto">
      <tr>
        <th>Call Name</th>
        <th>Registered Name</th>
        <th>Registry ID</th>
        <th>Date of Birth</th>
      </tr>
      <tr class="dog-row" *ngFor="let aDog of foundDogs.dogs" (click)="selectDog(aDog)">
        <td>{{ aDog.call_name }}</td>
        <td>{{ getRegisteredName(aDog) }}</td>
        <td>{{ getRegistryId(aDog) }}</td>
        <td>{{ getDateOfBirth(aDog) }}</td>
      </tr>
      <tr *ngIf="showDogTableSpinner">
        <td colspan="3">
          <mat-spinner class="mx-auto"></mat-spinner>
        </td>
      </tr>
      <tr *ngIf="noResultsFoundFromSearch">
        <td class="text-center" colspan="6">{{ searchResultsString }}</td>
      </tr>
    </table>
  </div>

</div>

<div class="footer">
  <button class="btn btn-sm btn-secondary btn-rounded ml-3" type="button" (click)="onCancel()">
    Cancel
  </button>
</div>
