import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers } from './reducers';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { VersionCheckService } from './services/versioncheck.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListingService } from './services/listing.service';
import { DogService } from './services/dog.service';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { AuthInterceptor } from './services/Auth.Interceptor';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './services/rollbar.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { NoCacheHeadersInterceptor } from './services/nocache-headers.interceptor';
import { HeaderComponent } from './header/header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ListingCardComponent } from './listing-card/listing-card.component';
import { FooterComponent } from './footer/footer.component';
import { PuppyListingComponent } from './puppy-listing/puppy-listing.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataTablesModule } from 'angular-datatables';
import { PuppyFindComponent } from './puppy-find/puppy-find.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatCardModule } from '@angular/material/card';
import { PuppyListingAdminComponent } from './puppy-listing-admin/puppy-listing-admin.component';
import { ListingFindCardComponent } from './listing-find-card/listing-find-card.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { StoreModule } from '@ngrx/store';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DogSearchComponent } from './dog-search/dog-search.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PuppyListingComponent,
    ErrorModalComponent,
    SpinnerComponent,
    InfoModalComponent,
    PuppyListingAdminComponent,
    ListingCardComponent,
    PuppyFindComponent,
    ListingFindCardComponent,
    DogSearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    DragDropModule,
    StoreModule.forRoot(reducers)
  ],
  providers: [ApiService, AuthService, VersionCheckService, NgbActiveModal, ListingService, DogService, DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
