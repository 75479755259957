<div class="container">
  <div class="wrapper">
    <div class="loaded-content" [class.blurred]="showSpinner">
      <div *ngIf="isLoggedIn && !finishedAdding">
        <div class="bg-light p-3 mt-2" style="margin-bottom: 6em;">
          <form [formGroup]="listingForm">
            <fieldset>
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="container card">
                    <div class="row" style="border-bottom: grey thin solid">
                      <div class="col-md-12 mb-2">
                        <h3 class="font-weight-bold p-3">Please complete this form to submit a listing for the Puppy Referral Program.</h3>
                        <div class="d-inline-flex" style="width:300px;">
                          <span style="align-self: center;margin-right: 10px; width:200px;">Listing Type:</span>
                          <select formControlName="listingType" class="form-select form-select-sm" aria-label="Listing Type" (change)="listingTypeChanged()">
                            <option value="puppies">New Litter</option>
                            <option value="planned">Planned Litter</option>
                            <option value="adult">Adult to Place</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1" *ngIf="listingType === 'puppies'">
                      <div class="col-md-4 font-weight-bold">
                        <span>Puppies Section</span>
                      </div>
                    </div>
                    <div class="row mt-3" *ngIf="listingType === 'puppies'">
                      <div class="col-md-4 offset-md-2">
                        <mat-form-field style="width: 100%;">
                          <select matNativeControl formControlName="malesCount" placeholder="Number of Males" >
                            <option value="">Select</option>
                            <option *ngFor="let in of counter(20); let i = index" [value]="i">{{i}}</option>
                          </select>
                          <div *ngIf="submitted && listingForm.controls['malesCount'].errors" class="invalid-feedback d-flex" style="justify-content: flex-end">
                            <div *ngIf="listingForm.controls['malesCount'].errors['required']" style="flex-direction: column;">This field is required</div>
                            <div *ngIf="listingForm.controls['malesCount'].errors['pattern']">This field is required</div>
                          </div>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field style="width: 100%;">
                          <select matNativeControl formControlName="femalesCount" placeholder="Number of Females" >
                            <option value="">Select</option>
                            <option *ngFor="let in of counter(20); let i = index" [value]="i">{{i}}</option>
                          </select>
                          <div *ngIf="submitted && listingForm.controls['femalesCount'].errors" class="invalid-feedback d-flex" style="justify-content: flex-end">
                            <div *ngIf="listingForm.controls['femalesCount'].errors['required']" style="flex-direction: column;">This field is required</div>
                            <div *ngIf="listingForm.controls['femalesCount'].errors['pattern']">This field is required</div>
                          </div>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row mt-1 border-top" *ngIf="listingType === 'planned'">
                      <div class="col-md-4 font-weight-bold">
                        <span>Planned Litter Section</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'planned'">
                      <div class="d-inline-flex" style="width:500px; margin: auto;">
                        <span style="align-self: center;margin-right: 10px; width:300px;">Expected Birth Month:</span>
                        <select formControlName="expectedMonth" class="form-select form-select-sm" aria-label="Expected birth Month">
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'planned'">
                      <div class="d-inline-flex" style="width:500px; margin: auto;">
                        <span style="align-self: center;margin-right: 10px; width:300px;">Expected Birth Year:</span>
                        <select formControlName="expectedYear" class="form-select form-select-sm" aria-label="Expected birth Year">
                          <option [value]="thisYear">{{ thisYear }}</option>
                          <option [value]="nextYear">{{ nextYear }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Description/Additional info:</mat-label>
                          <textarea class="w-100" matInput formControlName="description"></textarea>
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['description'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['description'].errors['maxLength']">Please enter a description shorter than 1000 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1 border-top" *ngIf="listingType === 'adult'">
                      <div class="col-md-4 font-weight-bold">
                        <span>Adult Section</span>
                      </div>
                    </div>
                    <div class="row mb-1" *ngIf="listingType === 'adult'">
                      <div class="col-md-12 text-center">
                        <button class="btn btn-secondary mb-3 w-50 mx-auto" (click)="lookupAdult()">Find Adult in Dog DB</button>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Adult Name</mat-label>
                          <input matInput formControlName="adultName">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['adultName'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['adultName'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['adultName'].errors['maxLength']">Please enter a name shorter than 1000 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Adult AKC or Foreign Registration Number</mat-label>
                          <input matInput formControlName="adultCertNum">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['adultCertNum'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['adultCertNum'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['adultCertNum'].errors['maxLength']">Please enter a certificate number shorter than 32 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Adult Health Clearances</mat-label>
                          <input matInput formControlName="adultHealth">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['adultHealth'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['adultHealth'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['adultHealth'].errors['maxLength']">Please enter a string shorter than 1024 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType === 'adult'">
                      <div class="d-inline-flex" style="width:300px; margin: auto;">
                        <span style="align-self: center;width:200px;">Adult Gender:</span>
                        <select formControlName="adultGender" class="form-select form-select-sm" aria-label="Adult Gender">
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mx-auto" *ngIf="listingType === 'adult' || listingType === 'puppies'">
                      <div class="col-md-6" style="text-align: right;">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Date of Birth</mat-label>
                          <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['dateOfBirth'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['dateOfBirth'].errors['required']">This field is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1 border-top" *ngIf="listingType !== 'adult'">
                      <div class="col-md-4 font-weight-bold">
                        <span>Sire Section</span>
                      </div>
                    </div>
                    <div class="row mb-1" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12 text-center">
                        <button class="btn btn-secondary mb-3 w-50 mx-auto" (click)="lookupSire()">Find Sire in Dog DB</button>
                      </div>
                    </div>
                    <div class="row d-none" *ngIf="listingType !== 'adult'">
                      <div class="col-md-7" style="text-align: right;">
                        <label>Sire Id:</label>
                      </div>
                      <div class="col-md-5">
                        <input class="w-100 form-control form-control-sm" name="sireId" formControlName="sireId">
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Sire Registered Name with Titles</mat-label>
                          <input matInput formControlName="sireName">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['sireName'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['sireName'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['sireName'].errors['maxLength']">Please enter a name shorter than 1000 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Sire AKC or Foreign Registration Number</mat-label>
                          <input matInput formControlName="sireCertNum">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['sireCertNum'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['sireCertNum'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['sireCertNum'].errors['maxLength']">Please enter a certificate number shorter than 32 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" *ngIf="listingType !== 'adult'">
                      <div class="col-md-5" style="text-align: right;">
                        <label>Sire Titles:</label>
                      </div>
                      <div class="col-md-7">
                        <input class="w-100 form-control form-control-sm" name="sireTitles" formControlName="sireTitles"
                               [ngClass]="{ 'is-invalid': submitted && listingForm.controls['sireTitles'].errors }">
                        <div *ngIf="submitted && listingForm.controls['sireTitles'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['sireTitles'].errors['maxLength']">Please enter a name shorter than 1024 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Sire Health Clearances</mat-label>
                          <input matInput formControlName="sireHealth">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['sireHealth'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['sireHealth'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['sireHealth'].errors['maxLength']">Please enter a string shorter than 1024 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1 border-top" *ngIf="listingType !== 'adult'">
                      <div class="col-md-4 font-weight-bold">
                        <span>Dam Section</span>
                      </div>
                    </div>
                    <div class="row mb-1" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12 text-center">
                        <button class="btn btn-secondary mb-3 w-50 mx-auto" (click)="lookupDam()">Find Dam in Dog DB</button>
                      </div>
                    </div>
                    <div class="row d-none" *ngIf="listingType !== 'adult'">
                      <div class="col-md-7" style="text-align: right;">
                        <label>Dam Id:</label>
                      </div>
                      <div class="col-md-5">
                        <input class="w-100 form-control form-control-sm" name="damId" formControlName="damId">
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Dam Registered Name with Titles</mat-label>
                          <input matInput formControlName="damName">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['damName'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['damName'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['damName'].errors['maxLength']">Please enter a name shorter than 1000 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Dam AKC or Foreign Registration Number</mat-label>
                          <input matInput formControlName="damCertNum">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['damCertNum'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['damCertNum'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['damCertNum'].errors['maxLength']">Please enter a certificate number shorter than 32 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" *ngIf="listingType !== 'adult'">
                      <div class="col-md-7" style="text-align: right;">
                        <label>Dam Titles:</label>
                      </div>
                      <div class="col-md-5">
                        <input class="w-100 form-control form-control-sm" name="damTitles" formControlName="damTitles"
                               [ngClass]="{ 'is-invalid': submitted && listingForm.controls['damTitles'].errors }">
                        <div *ngIf="submitted && listingForm.controls['damTitles'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['damTitles'].errors['maxLength']">Please enter a name shorter than 1024 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="listingType !== 'adult'">
                      <div class="col-md-12" style="text-align: left;">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Dam Health Clearances</mat-label>
                          <input matInput formControlName="damHealth">
                        </mat-form-field>
                        <div *ngIf="submitted && listingForm.controls['damHealth'].errors" class="invalid-feedback">
                          <div *ngIf="listingForm.controls['damHealth'].errors['required']">This field is required</div>
                          <div *ngIf="listingForm.controls['damHealth'].errors['maxLength']">Please enter a string shorter than 1024 letters</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 border-top">
                      <div class="col-md-4 font-weight-bold">
                        <span>Agreements Section</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10 mx-auto">
                        <h6>Please read and verify that you are in compliance with the information below.  You, as the breeder:</h6>
                        <ul class="aggrements">
                          <li>Must have no complaints or formal grievances which the NCA Board or Mediation Committee has found to have a basis in fact and were unresolved. NCA members and non‐members may file complaints, only NCA members may file formal grievances.</li>
                          <li>Must agree to always use a written Agreement/Contract in all Newfoundland transactions.</li>
                          <li>Must agree to make a life‐long commitment to every Newfoundland they produce and to cooperate in the rescue of any Newfoundland dogs they produce.</li>
                          <li>Must screen all puppies’ hearts at no earlier than 9 weeks old using a Board‐Certified (ACVIM) Cardiologist before placing and provide the screening results to the puppy buyer.</li>
                          <li>Agrees to abide by the NCA Code of Ethics and to cooperate in the investigation of any written complaints lodged against them.</li>
                          <li>Attests to the truthfulness of the information provided herein and authorizes the Newfoundland Club of America to verify said information.</li>
                          <li>Acknowledges that inclusion on the Puppy Referral Program is a privilege, not a right of membership. Removal from
                          the Puppy Referral Program is subject to the Club’s Arbitration process. Breeders will be removed after due process should
                          there be a complaint left outstanding against them as determined by the Arbitration process until resolved and will be removed
                            permanently if there has been a total of three (3) complaints lodged which were found against them even if resolved.</li>
                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10 mx-auto">
                        <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="agreements" formControlName="agreements"
                                 [ngClass]="{ 'is-invalid': submitted && listingForm.controls['agreements'].errors }">
                          <label class="form-check-label" for="agreements">
                            By checking this box, I agree to the above agreements
                          </label>
                          <div *ngIf="submitted && listingForm.controls['agreements'].errors" class="invalid-feedback">
                            <div *ngIf="listingForm.controls['agreements'].errors['required']">This box must be checked</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-12 text-center">
                        <button class="btn btn-primary mt-3 w-50 mx-auto" (click)="addListing()">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 text-left border-left">
                  <div class="container card">
                    <h4 class="font-weight-bold p-3">Complete Your Listing in these 7 Easy Steps</h4>
                    <ul>
                      <li>Login (you already did that!) the contact information from your NCA Member profile will be tied to this listing. You should be the breeder of record for this litter (or the owner for an adult placement).</li>
                      <li>Use the drop-down menu to select – planned litter, existing litter or adult</li>
                      <li>Complete the date information as needed (month/year for planned litter; date of birth for existing litter or adult)</li>
                      <li>Fill-in the registered name OR AKC number of the sire and the dam. If they are already part of the NCA database, their information will automatically fill-in. You will have a chance to edit that information if they have earned additional titles or gotten additional health clearances recently.</li>
                      <li>If either of the dogs is NOT in our database, you can manually enter their information</li>
                      <li>Registration Number – AKC or Official Registry of Country of Origin for Foreign born dogs</li>
                      <li>Registered name including titles</li>
                      <li>Health clearances</li>
                      <li>Click to agree to the conditions for using the Puppy Referral Program</li>
                      <li>Click submit. Your listing will go into “pending” status and will be reviewed within 7 days to verify that you do not have any outstanding grievances with the arbitration committee.</li>
                    </ul>
                    <p>Once it is approved, your listing will be live for 60 days. If you need to remove your listing early, or to extend your listing, please contact the <a href="mailto:breederreferral@ncadogs.org" style="color: blue;">Breeder Referral Committee</a> for assistance.</p>
                    <p>If you are having difficulty completing your listing, please <a href="mailto:itservices@newfdogclub.org" style="color: blue;">Contact Us</a> for help getting started.</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div *ngIf="isLoggedIn && finishedAdding">
        <h4>Thanks for submitting your listing</h4>
        <p>Your listing will now be reviewed and posted once approved.  If you have any questions
        please contact xxxxx</p>
        <button type="button" class="btn btn-primary mb-3" id="addAnotherButton" (click)="addAnother()">Add Another Listing</button>
      </div>
      <div *ngIf="!isLoggedIn">
        <h2>Login to create listing</h2>
        <p>Thank you for utilizing the NCA Puppy Referral Program. Log In here to add a new listing for a planned or existing litter of puppies or for an adult placement.</p>
        <h3>Requirements to use the Puppy Referral Listing System:</h3>
        <p>You, as the breeder:</p>
        <ul>
          <li>Must be a member in good standing for a minimum of 12 months.</li>
          <li>Must have no complaints or formal grievances which the NCA Board or Mediation Committee has found to have a basis in fact and were unresolved. NCA members and non‐members may file complaints, only NCA members may file formal grievances.</li>
          <li>Must agree to always use a written Agreement/Contract in all Newfoundland transactions.</li>
          <li>Must agree to make a life‐long commitment to every Newfoundland they produce and to cooperate in the rescue of any Newfoundland dogs they produce.</li>
          <li>Must screen all puppies’ hearts at no earlier than 9 weeks old using a Board‐Certified (ACVIM) Cardiologist before placing and provide the screening results to the puppy buyer.</li>
          <li>Must agree to abide by the NCA Code of Ethics and to cooperate in the investigation of any written complaints lodged against them.</li>
          <li>Attests to the truthfulness of the information provided herein and authorizes the Newfoundland Club of America to verify said information.</li>
          <li>Acknowledges that inclusion on the Puppy Referral Program is a privilege, not a right of membership. Removal from the Puppy Referral Program is subject to the Club’s Arbitration process. Breeders will be removed after due process should there be a complaint left outstanding against them as determined by the Arbitration process until resolved and will be removed permanently if there has been a total of three (3) complaints lodged which were found against them even if resolved.</li>
        </ul>
        <div class="row">
          <div class="col-md-6 mx-auto">
            <h4>NCA Member Login</h4>
            <p>Please enter the Email Address and Password that you use to access the NCA Membership Portal.
              If you do not remember your login information or have never setup an account on the NCA Membership Portal,
              please click the link below to setup your password.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 mx-auto">
            <form id="initMemberRegForm" [formGroup]="memberForm">
              <div class="form-row">
                <div class="col">
                  <input type="text" class="form-control" id="emailMember" name="emailMember" formControlName="emailMember" placeholder="Email">
                </div>
                <div class="col">
                  <input type="password" class="form-control" id="pwdMember" name="pwdMember" formControlName="pwdMember" placeholder="Password">
                </div>
                <div class="col mt-3">
                  <button type="button" class="btn btn-primary" id="loginMemberButton" (click)="loginMember()" [disabled]="!memberForm.valid">Login</button>
                </div>
              </div>
              <div class="form-row mt-1">
                <div class="col-md-11">
                  <a style="color: #0000ff;" href="https://members.ncanewfs.org/Sys/ResetPasswordRequest" target="_blank">Forgot your password or need to create one for the first time?</a>
                </div>
              </div>
              <hr>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
