import { Injectable, NgZone } from '@angular/core';
import { AuthModel } from '../models/auth.model';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
// import { shareReplay } from 'rxjs/operators';

@Injectable()
export class AuthService {

  constructor(private apiService: ApiService,
              private ngZone: NgZone) {
  }

  login(email: string, password: string): Observable<AuthModel> {
    return this.apiService.post('/login', {email, password})
      .pipe(
        tap(res => this.setSession)
//      .shareReplay();
      );
  }
  validateToken(): Observable<AuthModel> {
    return this.apiService.post('/validate/token', {})
      .pipe(
        tap(res => this.setSession)
//      .shareReplay();
      );
  }

  setSession(authResult: AuthModel) {
    const expiresAt = moment().add(authResult.expiresIn, 'second');

    if (navigator.cookieEnabled) {
      if (authResult.token) {
        localStorage.setItem('id_token', authResult.token);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
      }
    }
  }

  logout() {
    if (navigator.cookieEnabled) {
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
    }
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    try {
      if (navigator.cookieEnabled) {
        const expiration = localStorage.getItem('expires_at');
        if (expiration) {
          const expiresAt = JSON.parse(expiration);
          return moment(expiresAt);
        } else {
          return moment().subtract(1, 'day');
        }
      } else {
        return moment().subtract(1, 'day');
      }
    } catch (e) {
      return moment().subtract(1, 'day');
    }
  }
}
