<div class="container">
  <div class="wrapper">
    <div class="loaded-content" [class.blurred]="showSpinner">
      <div *ngIf="isLoggedIn">
        <div cdkDropListGroup>
          <div class="row">
            <div class="col-md-12">
              <h3>Administration of Puppy Referral Program</h3>
              <p>Length of Membership has been validated at initial login. Each lister has agreed to the Listing Privilege conditions as outlined by the Board.
                Please check each lister for outstanding grievances and move listing from Pending to Active or Declined by dragging the listing box into the appropriate column.
                Listing will move automatically to expired after 60 days. Listers may request to have the listing closed if the puppies are no longer available while still active within the first 30 days.
                To do this, simply drag the listing from approved to expired to close it.
                Listers may request to have an expired listing re-activated for an additional 60 days - simply drag the listing from expired back into the active column.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <h3>Pending</h3>
              <div cdkDropList #pendingList="cdkDropList" [cdkDropListData]="pending"
                   class="puppy-list" (cdkDropListDropped)="drop($event)">
                <div class="puppy-box" *ngFor="let listing of pending" cdkDrag><app-listing-card [item]="listing"></app-listing-card></div>
              </div>
            </div>
            <div class="col-md-4">
              <h3>Approved</h3>
              <div cdkDropList #approvedList="cdkDropList" [cdkDropListData]="approved"
                   class="puppy-list" (cdkDropListDropped)="drop($event)">
                <div class="puppy-box" *ngFor="let listing of approved" cdkDrag><app-listing-card [item]="listing"></app-listing-card></div>
              </div>
            </div>
            <div class="col-md-4">
              <h3>Expired</h3>
              <div cdkDropList #expiredList="cdkDropList" [cdkDropListData]="expired"
                   class="puppy-list" (cdkDropListDropped)="drop($event)">
                <div class="puppy-box" *ngFor="let listing of expired" cdkDrag><app-listing-card [item]="listing"></app-listing-card></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <h3>Denied</h3>
              <div cdkDropList #deniedList="cdkDropList" [cdkDropListData]="denied"
                   class="puppy-list" (cdkDropListDropped)="drop($event)">
                <div class="puppy-box" *ngFor="let listing of denied" cdkDrag><app-listing-card [item]="listing"></app-listing-card></div>
              </div>
            </div>
            <div class="col-md-4">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoggedIn">
        <h2>Login to administer listing</h2>
        <p>Log In here to review and manage all puppy listings.</p>
        <div class="row">
          <div class="col-md-6 mx-auto">
            <h4>NCA Member Login</h4>
            <p>Please enter the Email Address and Password that you use to access the NCA Membership Portal.
              If you do not remember your login information or have never setup an account on the NCA Membership Portal,
              please click the link below to setup your password.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 mx-auto">
            <form id="initMemberRegForm" [formGroup]="memberForm">
              <div class="form-row">
                <div class="col">
                  <input type="text" class="form-control" id="emailMember" name="emailMember" formControlName="emailMember" placeholder="Email">
                </div>
                <div class="col">
                  <input type="password" class="form-control" id="pwdMember" name="pwdMember" formControlName="pwdMember" placeholder="Password">
                </div>
                <div class="col mt-3">
                  <button type="button" class="btn btn-primary" id="loginMemberButton" (click)="loginMember()" [disabled]="!memberForm.valid">Login</button>
                </div>
              </div>
              <div class="form-row mt-1">
                <div class="col-md-11">
                  <a style="color: #0000ff;" href="https://members.ncanewfs.org/Sys/ResetPasswordRequest" target="_blank">Forgot your password or need to create one for the first time?</a>
                </div>
              </div>
              <hr>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </div>
  </div>
</div>
