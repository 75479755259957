import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User, UserState } from '../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { ListingService } from '../services/listing.service';
import { DogService } from '../services/dog.service';
import { DatePipe, formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import * as userReducer from '../reducers/user';
import { takeUntil } from 'rxjs/operators';
import * as userActions from '../actions/user.actions';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Listing } from '../models/listing.model';
import { initialState } from '../reducers/user';
import { AuthModel } from '../models/auth.model';

@Component({
  selector: 'app-puppy-find',
  templateUrl: './puppy-find.component.html',
  styleUrls: ['./puppy-find.component.scss']
})
export class PuppyFindComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoggedIn: boolean;
  showSpinner: boolean;
  currentUser: UserState = initialState;
  stateUser: UserState = initialState;
  memberForm: FormGroup;
  errorModalRef: NgbModalRef | undefined;
  approved: Listing[];
  zipcode: string;
  listings: string;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private router: Router,
              private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private auth: AuthService,
              private api: ApiService,
              private listingService: ListingService,
              private dogService: DogService,
              private datePipe: DatePipe,
              private ngZone: NgZone,
              public store: Store<UserState>) {
    this.isLoggedIn = false;
    this.showSpinner = false;
    this.approved = [];
    this.zipcode = '';
    this.listings = '';
    this.memberForm = this.fb.group({
      emailMember: ['', Validators.required],
      pwdMember: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.currentUser = {user: {
        waId: 0, lastName: '', firstName: '', identity: '', email: '', status: '', groups: [],
        memberSince: '', breederEmail: '', kennelName: '', facebook: '', website: ''
      }, token: '', authenticated: false};
    this.showSpinner = false;
    this.store.select(userReducer.getUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: UserState) => {
        this.ngZone.run(() => {
          this.currentUser = user;
          this.isLoggedIn = user.authenticated;
          if (!user.token) {
            setTimeout(() => {
              this.showSpinner = true;
              this.auth.validateToken()
                .subscribe(
                  (res) => {
                    this.showSpinner = false;
                    if (res.valid) {
                      const newUser: User = {
                        identity: '',
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        waId: res.waId,
                        memberSince: res.memberSince,
                        breederEmail: res.breederEmail,
                        kennelName: res.kennelName,
                        facebook: res.facebook,
                        website: res.website,
                        status: res.status,
                        groups: res.groups
                      };
                        let canUseThisService = true;
                      if (res.status !== 'Active') {
                          canUseThisService = false;
                          console.log('member is not active');
                          setTimeout(() => {
                            this.isLoggedIn = false;
                            this.rollbar.info('Member is not active for user: ' + res.email);
                            this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                            this.errorModalRef.componentInstance.errorMessage = '';
                            this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
                          }, 500);
                        }
                        if (canUseThisService) {
                          if (navigator.cookieEnabled) {
                            const oldToken = localStorage.getItem('id_token');
                            if (oldToken) {
                              this.store.dispatch(userActions.setTokenUserAction({inToken: oldToken}));
                            }
                          }
                          this.isLoggedIn = true;
                          this.store.dispatch(userActions.setAuthenticatedUserAction({inAuth: true}));
                          this.store.dispatch(userActions.updateUserAction({inUser: newUser}));
                          this.loadListingData();
                        }
                    } else {
                      this.isLoggedIn = false;
                    }
                  },
                  (err) => {
                    this.isLoggedIn = false;
                    this.showSpinner = false;
                  }
                );
            }, 500);
          }

        });
      });
  }

  loadListingData() {
    if (this.currentUser.authenticated) {
      this.showSpinner = true;
      this.listingService.getListings(this.zipcode)
        .subscribe(data => {
          this.showSpinner = false;
          this.approved = data.approved;
          this.listings = '';
          this.approved.forEach(data => {
            this.listings += 'Listing Type: ' + data.listingType + "\r\n";
            this.listings += 'Breeder: ' + data.breederInfo?.firstName + ' ' + data.breederInfo?.lastName + "\r\n";
            this.listings += 'Date Of Birth: ' + this.getDateOfBirth(data) + "\r\n";
            if (data.listingType === 'puppies') {
              this.listings += 'Males: ' + data.malesCount + ', Females: ' + data.femalesCount + "\r\n";
            } else if (data.listingType === 'adult') {
              this.listings += 'Adult Name: ' + data.adultName + "\r\n";
              this.listings += 'Adult Gender: ' + data.adultGender + "\r\n";
              this.listings += 'Adult Health: ' + data.adultHealth + "\r\n";
            }
            const breederEmail = data.breederInfo?.breederEmail
            if (breederEmail && (breederEmail.length > 0)) {
              this.listings += 'Breeder Email: ' + breederEmail + "\r\n";
            }
            const breederWebsite = data.breederInfo?.breederWebsite
            if (breederWebsite && (breederWebsite.length > 0)) {
              this.listings += 'Breeder Website: ' + breederWebsite + "\r\n";
            }
            this.listings += 'Address: ' + data.breederInfo?.city + ', ' + data.breederInfo?.state + ' ' + data.breederInfo?.zipCode + "\r\n";
            this.listings += 'Sire Name: ' + data.sireName + "\r\n";
            this.listings += 'Sire Health: ' + data.sireHealth + "\r\n";
            this.listings += 'Dam Name: ' + data.damName + "\r\n";
            this.listings += 'Dam Health: ' + data.damHealth + "\r\n";
            this.listings += 'Description: ' + data.description + "\r\n";
            this.listings += '---------------------------------------' + "\r\n";
          });
        });
    }
  }

  getDateOfBirth(listing: Listing): string | Date {
    if (listing.dateOfBirth !== null) {
      let bd = new Date(Date.parse(<string>listing.dateOfBirth));
      return formatDate(bd, 'MMM dd, YYYY', 'en_US');
    } else {
      return 'Expected: ' + this.getMonth(listing.expectedMonth) + ', ' + listing.expectedYear;
    }
  }

  getMonth(month: number | undefined): string {
    switch (month) {
      case 0: return 'January';
      case 1: return 'February';
      case 2: return 'March';
      case 3: return 'April';
      case 4: return 'May';
      case 5: return 'June';
      case 6: return 'July';
      case 7: return 'August';
      case 8: return 'September';
      case 9: return 'October';
      case 10: return 'November';
      case 11: return 'December';
    }
    return 'January';
  }

  loginMember() {
    this.showSpinner = true;
    this.auth.login(this.memberForm.controls['emailMember'].value, this.memberForm.controls['pwdMember'].value)
      .subscribe(
        (res) => this.saveLoginData(res),
        (err) => this.manageLoginError(err)
      );
  }

  manageLoginError(err: AuthModel) {
    this.ngZone.run(() => {
      this.showSpinner = false;
      setTimeout(() => {
        this.rollbar.info('Login failure: ' + err);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  saveLoginData(res: AuthModel) {
    this.showSpinner = false;
    this.activeModal.close();
    if (res.valid) {

      this.auth.setSession(res);
      const newUser: User = {
        identity: '',
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        waId: res.waId,
        memberSince: res.memberSince,
        breederEmail: res.breederEmail,
        kennelName: res.kennelName,
        facebook: res.facebook,
        website: res.website,
        status: res.status,
        groups: res.groups
      };
      let canUseThisService = true;
      if (res.status !== 'Active') {
        canUseThisService = false;
        console.log('member is not active');
        setTimeout(() => {
          this.isLoggedIn = false;
          this.rollbar.info('Member is not active for user: ' + res.email);
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'You must be an active member of the NCA for longer than 1 year in order to use this service. Please renew your membership.';
        }, 500);
      }
      if (canUseThisService) {
        if (res.token) {
          this.store.dispatch(userActions.setTokenUserAction({inToken: res.token}));
        }
        this.store.dispatch(userActions.setAuthenticatedUserAction({inAuth: true}));
        this.store.dispatch(userActions.updateUserAction({inUser: newUser}));
        this.loadListingData();
      }
    } else {
      console.log('login failed');
      setTimeout(() => {
        this.isLoggedIn = false;
        this.rollbar.info('Login failure for user: ' + res.email);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    }
  }

  sortDogs() {

  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
