import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dog, DogHealth, DogsTable, DogTitles, FoundDogs } from '../models/dog.model';

@Injectable()
export class DogService {

  private _searchName: string;

  constructor(private apiService: ApiService) {
    this._searchName = '';
  }

  get searchName(): string { return this._searchName; }
  set searchName(value: string) { this._searchName = value;}

  public getDogsTable(body: any, isLoggedIn: boolean): Observable<DogsTable> {
    let dogOptionString = '?subStr=' + this._searchName;
    return this.apiService.post('/dogs/table' + dogOptionString, body);
  }

  public findDogs(term: string, maxAge: number, isLoggedIn: boolean): Observable<FoundDogs> {
    let maxAgeString = '';
    if (maxAge > 0) {
      maxAgeString = '&dobgt=' + maxAge;
    }
    return this.apiService.get('/dogs?subStr=' + term + '&regId=' + term + maxAgeString);
  }

  public getDogTitles(dogId: number | undefined): Observable<DogTitles> {
    return this.apiService.get('/dogs/' + dogId + '/titles');
  }
  public getDogHealth(dogId: number | undefined): Observable<DogHealth> {
    return this.apiService.get('/dogs/' + dogId + '/health');
  }
}
